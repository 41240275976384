@import "font/stylesheet.css";

/* Box sizing rules */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */

ul[class],
ol[class] {
  padding: 0;
}

a:hover {
  text-decoration: none;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */

ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */

article>*+* {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
  font: inherit;
}

.mobile {
  display: none;
}

.slick-arrow{
  display: none !important;
}

#mqg-tips{
  font-weight: bold;
  color: #05A9FD;
  text-transform: uppercase;
}

#iframes{
  background-color: #fff;
  padding-top: 5em;
}

#iframes h3{
  color: #05A9FD;
}

#iframes img{
  display: inline-block;
}

.nota-principal{
  position: relative;
}

.nota-principal .background-gradient{
  position: absolute;
  top: 100px;
  width: 100%;
  max-width: 100vw;
  background-image: url(../img/fondo-degrade.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  z-index: 1;
}

.nota-principal .container{
  z-index: 5;
  position: relative;
}

.slick-active.slick-current{
  z-index: 2;
}

#error404 main{
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#test-error .container-fluid{
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../img/fondo-404.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-color: #F4F4F4;
}

#test-error h2{
  color: #00B5F3;
  font-weight: bold;
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.carousel.carousel-fade .carousel-item {
  display: block;
  opacity: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity ease-out 0.2s;
}

.carousel.carousel-fade .carousel-item.active {
  opacity: 1 !important;
}

/*FONTS */

@font-face {
  font-family: 'NunitoSans';
  src: local('NunitoSans'), url('../fonts/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamRounded';
  src: local('GothamRounded'), url('../fonts/GothamRoundedMedium_21022.ttf') format('truetype');
}

/* ESTILOS MAS QUE GLUCOSA */


html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  color: #333333;
}

.bg-celeste-dark {
  background-color: #0085c9;
  color: #fff;
}

.bg-celeste-light {
  background-color: #44cce9;
}

.bg-gris {
  background-color: #f4f4f4;
}

.bg-white {
  background-color: #fff;
}

.text-yellow {
  color: #fc9d4d !important;
}

.text-blue {
  color: #05A9FD !important;
}

.text-celeste {
  color: #0085c9;
}

.text-dark {
  color: #333333;
}

h2 {
  font-weight: 500;
}

h3 {
  font-weight: 600;
}

p b {
  font-weight: 700;
}

.gradient {
  position: relative;
}

.gradient:before {
  position: absolute;
  content: '';
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgb(44, 122, 200);
  background: rgb(44, 122, 200);
  background: -moz-linear-gradient(142deg,
      rgba(44, 122, 200, 0.4) 0%,
      rgba(16, 74, 91, 0.4) 100%);
  background: -webkit-linear-gradient(142deg,
      rgba(44, 122, 200, 0.4) 0%,
      rgba(16, 74, 91, 0.4) 100%);
  background: linear-gradient(142deg,
      rgba(44, 122, 200, 0.4) 0%,
      rgba(16, 74, 91, 0.4) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#2c7ac8", endColorstr="#104a5b", GradientType=1);
  -webkit-transition: opacity ease-out 0.2s;
  -moz-transition: opacity ease-out 0.2s;
  -o-transition: opacity ease-out 0.2s;
  transition: opacity ease-out 0.2s;
}

.fondo-gradient-blue {
  background: rgb(44, 122, 200);
  background: -moz-linear-gradient(142deg,
      rgba(44, 122, 200, 1) 0%,
      rgba(90, 193, 221, 1) 100%);
  background: -webkit-linear-gradient(142deg,
      rgba(44, 122, 200, 1) 0%,
      rgba(90, 193, 221, 1) 100%);
  background: linear-gradient(142deg,
      rgba(44, 122, 200, 1) 0%,
      rgba(90, 193, 221, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#2c7ac8", endColorstr="#5ac1dd", GradientType=1);
  color: #fff;
}

#header-interna {
  margin-top: 5rem;
}

#header-interna .miga-pan {
  font-size: .8rem;
  color: #9F9F9F;
  margin-bottom: .5rem;
}

#header-interna h3 {
  font-size: 3rem;
  color: #05A9FD;
  font-weight: bold;
}

#header-interna h3:after {
  content: '';
  display: flex;
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background: #6db5db;
  background: linear-gradient(to right, #6db5db 0%,#f5b563 30%);
  margin-bottom: 2rem;
}

#header-main {
  position: relative;
  height: 100px;
  transition: top 0.5s;
}

#header-main:after {
  content: '';
  position: absolute;
  bottom: -15px;
  width: 90%;
  left: 5%;
  height: 1px;
  background-color: #0085c9;
}

#home #header-main:after {
  content: none;
}

#header-main .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.mqg-logo svg {
  width: 150px;
  fill: #0085c9;
}

#home .mqg-logo svg {
  fill: #fff;
  transition: fill 0.5s;
}

.header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 80px;
}

.header-right .enfermeria {
  display: flex;
  align-items: center;
  margin-right: 4rem;
  color: #0085c9;
}

.header-right .enfermeria:hover p {
  text-decoration: underline;
}

/* NAVBAR HOME FIXED */

#home #fixed #header-main {
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 50;
  transition: top 0.5s, background-color 0.5s;
}

#home #fixed #header-main .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

#home #fixed #header-main .mqg-logo svg {
  fill: #0085c9;
  transition: fill 0.5s;
}

#home #fixed .navbar-toggler {
  background-color: white;
  transition: background-color 0.5s;
}

#home #fixed .is-active .hamburger-inner,
#home #fixed .is-active .hamburger-inner::before,
#home #fixed .is-active .hamburger-inner::after {
  background-color: #fc9d4d;
}

#home #fixed .hamburger-inner,
#home #fixed .hamburger-inner::before,
#home #fixed .hamburger-inner::after{
  background-color: #fc9d4d;
}


#home .nav-open #fixed .navbar-toggler {
  background-color: #ffffff !important;
}



/* ---------------------- */

#home .header-right .enfermeria {
  color: #fff;
}

.header-right .enfermeria svg {
  fill: #0085c9;
  margin-right: 0.8rem;
}

#home .header-right .enfermeria svg {
  fill: #fff;
}

.fixed-wrapper {
  position: fixed;
  width: 100%;
  left: 0;
  top: 1%;
  margin: auto;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 999;
}

.fixed-container {
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
}

.navbar-toggler {
  pointer-events: all;
  position: relative;
  border-radius: 50%;
  background-color: #fff;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  z-index: 50;
}

#home .navbar-toggler {
  background-color: #fff;
  transition: background-color 0.5s;

}

#home .navbar-toggler.is-active {
  background-color: white;
}

.hamburger {
  padding: 15px 15px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #fc9d4d;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: rgba(44, 122, 200, 1);
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: none !important;
}

/*
   * Spin
   */

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: #fc9d4d;
}

#home .is-active .hamburger-inner,
#home .is-active .hamburger-inner::before,
#home .is-active .hamburger-inner::after {
  background-color: #fc9d4d;
}

#home .hamburger-inner,
#home .hamburger-inner::before,
#home .hamburger-inner::after {
  background-color: #fc9d4d;
}

.menu-principal {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  height: 100%;
  width: 90%;
  width: 768px;
  padding-top: 5%;
  display: flex;
  align-items: flex-start;
  padding: 2em 3.5em;
  background: #05A9FD;
  overflow: auto;
  transform: translateZ(0);
  transform: translateX(100%);
  transition: transform 0.5s cubic-bezier(0.07, 0.23, 0.34, 1);
}

.nav-open .menu-principal {
  overflow: hidden;
  transform: translateX(0);
  -webkit-box-shadow: -5px 0px 15px -5px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: -5px 0px 15px -5px rgba(0, 0, 0, 0.58);
  box-shadow: -5px 0px 15px -5px rgba(0, 0, 0, 0.58);
}

.menu-principal h2 {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #aaaaaa;
  margin: 1.5em 0;
}

.menu-principal {
  padding-left: 6rem;
  padding-bottom: 5%;
  padding-top: 5%;
}

.menu-principal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.menu-slider-right {
  margin-top: 10vh;
  padding: 0;
  margin-left: 0;
}

.menu-items-container h2 {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #fff;
  font-weight: normal;
}

.menu-slider-right li {
  list-style: none;
  padding-bottom: 0.3rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* .menu-slider-right a.sub {
  border-bottom: #05A9FD solid 3px;
} */

.menu-slider-right a.sub {
  color: white;
  display: inline-block;
  margin: 0;
}

.menu-slider-right a.sub:after {
  display: block;
  content: '';
  border-bottom: solid 3px #ffffff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.menu-slider-right a.sub:hover:after {
  transform: scaleX(1);
}


.menu-slider-right li a {
  font-size: 2rem;
  color: #ffffff;
  -moz-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  text-decoration: none;
}

.menu-slider-right li a.nav-link {
  padding: 0;
}

.menu-slider-right li:hover a {
  color: #000000;
}

.menu-redes {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.menu-redes a {
  color: #ffffff;
  font-size: 2rem;
  margin-right: 1.5rem;
  -moz-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.menu-redes a:hover img {
  opacity: 0.8;
}

/* HOME */

#home header#header-main {
  background-color: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 10;
}

/* HOME SLIDER BANNER */

#h-main {
  height: 80vh;
}

#h-main .carousel,
#h-main .carousel-inner,
#h-main .carousel-item {
  height: 100vh;
  padding-top: 7rem;
}

#h-main .carousel-inner {
  height: 80vh !important;
  display: inline-grid !important;
  align-items: center;

  /* background: rgb(56, 183, 234);
  background: -moz-radial-gradient(circle,
      rgba(56, 183, 234, 1) 13%,
      rgba(32, 151, 210, 1) 98%);
  background: -webkit-radial-gradient(circle,
      rgba(56, 183, 234, 1) 13%,
      rgba(32, 151, 210, 1) 98%);
  background: radial-gradient(circle,
      rgba(56, 183, 234, 1) 13%,
      rgba(32, 151, 210, 1) 98%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#38b7ea", endColorstr="#2097d2", GradientType=1); */
}

/* #h-main #diabetes {
  background-image: url(../img/diabetes-banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#h-main #ejercicio {
  background-image: url(../img/ejercicio-banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#h-main #enfermeria {
  background-image: url(../img/servicio-banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} */

#h-main .container-banner {
  /* background-image: url(../img/helado-banner.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#h-main .carousel-item {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

#h-main .carousel-item .row {
  display: flex;
  align-items: center;
}

#h-main .carousel-item .golosina {
  max-height: 90%;
}

#h-main .banner .tag {
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: white;
}

#h-main .banner h2 {
  font-size: 4rem;
  text-transform: uppercase;
  color: white;
  font-family: Impulsa, sans-serif;
}

#h-main .banner button {
  margin-top: 2rem;
  color: #fff;
  box-shadow: inset 0px 0px 0px 2px #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 2em;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

#h-main .banner button:hover {
  background-color: #fff;
  color: #0085C9;
}

#h-main .fixed-button button {
  margin-top: 2rem;
  color: #fff;
  padding: 15px 30px;
  border-radius: 2em;
  display: flex;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #05A9FD;
  font-size: 1rem;
  line-height: 1.5;
  border-color: #05A9FD;
  border-style: none;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99;
  transition: all 0.2s ease;
}

#h-main .fixed-button button:hover {
  background-color: #41b3ed;
}



/* --------------------- */

section {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

header.seccion {
  margin-bottom: 3rem;
}

header.seccion h2 {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;
}

header.seccion img {
  width: 100%;
}

/* ICONOS HOME */

#home-selector {
  padding: 2rem 0;
}

#home-selector .iconos {
  text-align: -webkit-center;
  margin-top: 1rem;
  transition: transform 0.2s;
}

#home-selector .iconos:hover {
  transition: transform 0.2s;
  transform: scale(1.1);
}

#home-selector .iconos p {
  align-self: center;
  font-size: 1rem;
  color: #3B3B3B;
  text-decoration: none;
  font-weight: bold;
}

#home-selector .iconos:hover p {
  color: #F5B563;
}

#home-selector .iconos:hover svg g text {
  fill: #F5B563;
  transition: transform 1s;
}

#home-selector #icono-normal:hover svg g g path {
  fill: #ffffff;
  transition: transform 1s;
}

#home-selector #icono-normal svg g g path {
  fill: #F5B563;
  transition: transform 1s;
}

#home-selector #icono-diabetes:hover svg g {
  fill: #F5B563;
  transition: transform 1s;

}

#home-selector #icono-normal:hover svg g {
  fill: #F5B563;
  transition: transform 1s;

}

#home-selector #icono-diabetes:hover svg g.tag-g path {
  fill: #ffffff;
  transition: transform 1s;

}

#home-selector #icono-diabetes svg g.tag-g path {
  fill: #F5B563;
  transition: transform 1s;

}

.proximamente-modal {
  margin-bottom: 3rem;
  margin-top: 3rem;
  color: #0085C9;
  font-size: 2rem;
}

/* ----------- */

/* TODO LO QUE NECESITAS SABER SECTION */

.necesitas-saber {
  background-color: #F4F4F4;
  padding-bottom: 0 !important;
}

.necesitas-saber h6{
  font-weight: bold;
}

.necesitas-saber h3 {
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: bold;
}

.necesitas-saber span{
  font-weight: bold;
}

.necesitas-saber .men-image {
  display: block;
}

.necesitas-saber .female-image {
  display: block;
  margin-left: 2rem;
}

.necesitas-saber .images-mobile {
  display: none;
}

.necesitas-saber .animacion-iframe {
  width: 100%;
  height: 550px;
  position: relative;
  bottom: -3.7rem;
}

.necesitas-saber #animation_container {
  margin-bottom: 0px !important;
}

.necesitas-saber .texto-animacion {
  margin-top: 3rem;
  padding-left: 30%;
  text-align: end;
}

.necesitas-saber .texto-animacion .principal {
  font-size: 1.1rem;
  font-weight: 700;
}

.necesitas-saber .texto-animacion .subtitle {
  color: #0085C9;
  font-weight: 700;
}

/*------------------------------------ */

/* SECCION TU ESPACIO DE DIABETES EN HOME  */

#espacio-diabetes-in-home .titles {
  border-bottom: transparent 2px solid;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

#espacio-diabetes-in-home .titles:after{
  content: '';
  height: 3px;
  background: black;
  width: 100%;
  margin-top: 1rem;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6db5db+0,f5b563+30 */
  background: #6db5db; /* Old browsers */
  background: -moz-linear-gradient(left,  #6db5db 0%, #f5b563 30%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #6db5db 0%,#f5b563 30%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #6db5db 0%,#f5b563 30%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6db5db', endColorstr='#f5b563',GradientType=1 ); /* IE6-9 */


}

#espacio-diabetes-in-home .title-notas {
  display: flex;
  cursor: pointer;
}

#espacio-diabetes-in-home .title-note svg {
  display: none;
}

#espacio-diabetes-in-home .title-notas:hover svg path {
  stroke: #d3833f;
}

#espacio-diabetes-in-home .title-notas:hover h3 {
  color: #d3833f;
}

#espacio-diabetes-in-home .title-notas-mobile {
  display: none;
}

#espacio-diabetes-in-home .title-note h3 {
  color: #0085C9;
}

#espacio-diabetes-in-home .title-note h3:hover {
  color: #05A9FD;
}


#espacio-diabetes-in-home .title-notas svg {
  height: fit-content;
  margin-right: .5rem;
  cursor: pointer;
}


#espacio-diabetes-in-home .title-notas h3 {
  color: #05A9FD;
}

#espacio-diabetes-in-home .title-notas .yellow-text{
  color: #fc9d4d;
}

#espacio-diabetes-in-home .nota-principal img {
  cursor: pointer;
}

#espacio-diabetes-in-home .nota-principal h4 {
  margin-bottom: 1rem;
  color: #3B3B3B;
  font-weight: bold;
}

#espacio-diabetes-in-home .nota-principal p {
  margin-bottom: 1rem;
}

#espacio-diabetes-in-home .otras-notas {
  border-left: #DEDEDE solid 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#espacio-diabetes-in-home .otras-notas .nota {
  margin: 1rem .5rem
}

#espacio-diabetes-in-home .otras-notas img {
  width: 180px;
  cursor: pointer;
  max-width: initial;
}

#espacio-diabetes-in-home .otras-notas h5 {
  width: 70%;
  margin-left: 1rem;
  cursor: pointer;
  color: #3B3B3B;
}

#espacio-diabetes-in-home .otras-notas h5:hover {
  color: #05A9FD;
}

#espacio-diabetes-in-home h4 {
  cursor: pointer;
}

#espacio-diabetes-in-home h4:hover {
  color: #fc9d4d;
}





/* ---------------------- */

/* BUTTON COMPARTIR */

.container-button-compartir {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.container-button-compartir-espacio-diabetes {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  position: absolute;
  right: 4rem;
  bottom: 10rem;
}

.container-button-compartir-espacio-diabetes .button-compartir {
  background-image: url(../img/icono-compartir-blanco.png);
}


.button-compartir {
  background-image: url(../img/iconCompartir.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: fit-content;
}

.button-compartir a:after {
  color: transparent !important;
}

.button-compartir .icon {
  margin-right: .5rem;
  border-radius: 50%;
}

/* BUTTON CALENDAR */

.calendar-button {
  cursor: pointer;
  margin-top: 1rem;
  margin-left: .5rem;
}

.calendar-button .react-add-to-calendar .react-add-to-calendar__dropdown {
  position: absolute;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
  width: 12rem;
  z-index: 10;
}

.calendar-button .react-add-to-calendar .react-add-to-calendar__dropdown ul {
  margin-top: 1rem;
}

.calendar-button .react-add-to-calendar .react-add-to-calendar__dropdown a {
  text-decoration: none;
  color: black;
}

.calendar-button .react-add-to-calendar a.react-add-to-calendar__button {
  position: relative;
  width: 350px;
  text-align-last: center;
  left: .5rem;
}

/* NUMEROS RIESGO CONTAINER */

.numeros-riesgo-container {
  display: flex;
}

/* ---------------------- */

/*  VIDEO  */

#container-video {
  width: 50%;
}

/* ----------- */

/* NUMEROS SLIDER */

#numeros-slider {
  width: 50%;
  padding: 2rem 15px;
  background-image: url(../img/fondo-mundi.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  transition: all 500ms;

}

#numeros-slider-fuentes {
  width: 50%;
  padding: 4rem;
  background-color: #D9EBF4;
  transition: all 500ms;
}

#numeros-slider-fuentes .fuente-container {
  background-color: white;
  height: 100%;
}

#numeros-slider-fuentes .fuente-container button {
  background-color: transparent;
  border: none;
  display: flex;
  margin-left: 70%;
  position: relative;
  top: 2rem;
  color: #0085c9;
}

#numeros-slider-fuentes .fuente-container button:hover {
  color: #034c71;
}

#numeros-slider-fuentes .fuente-container p {
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 10%;
}

#numeros-slider .slick-dots {
  left: 0;
  bottom: -2rem;
  margin-left: 0;
}

#numeros-slider .slick-arrow {
  display: none !important;
}

#numeros-slider .titles {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 3rem;
}

#numeros-slider .fuentes-button {
  text-align-last: end;
  margin-top: 2rem;
  padding-right: 5rem;
}

#numeros-slider .fuentes-button button {
  border: none;
  background-color: transparent;
  color: #0085c9;
}

#numeros-slider .fuentes-button button:hover {
  color: #034c71;
}

#numeros-slider .titles h5 {
  font-size: 1rem;
  color: #0085C9;
}

#numeros-slider .text .line-one {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
}

#numeros-slider .text .line-two {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

#numeros-slider .text .line-three {
  font-size: 1.2rem;
  color: #0085c9;
  width: 90%;
}

/* -------------  */

/* HOME ESPACIO DE DIABETES */

#espacio-diabetes-home {}

#espacio-diabetes-home .nota-principal {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#espacio-diabetes-home .nota-principal .miga-pan {
  color: #9F9F9F;
  text-transform: uppercase;
  margin-bottom: .5rem;
  font-size: .8rem;
}

#espacio-diabetes-home .nota-principal .content {
  display: flex;
}

#espacio-diabetes-home .nota-principal img {
  max-width: initial;
  width: 100%;
}

#espacio-diabetes-home .nota-principal h3 {
  margin-bottom: 2rem;
  color: #05A9FD;
}

#espacio-diabetes-home .nota-principal h3:after {
    display: flex;
    content: '';
    height: 3px;
    width: 100%;
    margin-top: 1rem;
    background: #6db5db;
    background: linear-gradient(to right, #6db5db 0%,#f5b563 30%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6db5db', endColorstr='#f5b563',GradientType=1 );
}

#espacio-diabetes-home .detail {
  padding-left: 45px;

}

#espacio-diabetes-home .detail h4 {
  font-size: 1.8rem;
  color: #0085C9;
  margin-bottom: 1.5rem;
}

#espacio-diabetes-home .otras-notas .content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.multi-carousel .nota {
  margin: 15px;
  padding: 15px;

}

.multi-carousel .nota h5 {
  margin-top: 1rem;
  color: #0085c9;
  font-size: 1.5rem;

}

.multi-carousel .nota .detail {
  padding-left: 0 !important;
}

.multi-carousel .nota img {
  width: 350px;
}

.multi-carousel .nota .mas-info {
  display: flex;
  justify-content: flex-end;
}

.multi-carousel .nota .mas-info svg {
  width: 25px;
}

.multi-carousel .nota .mas-info p {
  font-size: 1rem;
  align-self: center;
  margin-left: .5rem;
  color: #0085c9;
}

.multi-carousel .nota:hover h5,
.multi-carousel .nota:hover svg path {
  color: #05A9FD;
  stroke: #05A9FD;
}

.multi-carousel .react-multiple-carousel__arrow--right {
  right: -.8rem;
  background: transparent;
  bottom: 0px;
}

.multi-carousel .react-multiple-carousel__arrow--right:focus,
.multi-carousel .react-multiple-carousel__arrow--left:focus {
  outline: none;
}


.multi-carousel .react-multiple-carousel__arrow--right:before,
.multi-carousel .react-multiple-carousel__arrow--left:before {
  color: #0085c9;
  font-size: 35px;
}

.multi-carousel .react-multiple-carousel__arrow--left {
  left: -.5rem;
  background: transparent;
  bottom: 0px;

}

.react-multiple-carousel__arrow {
  z-index: 99 !important;
}


#espacio-diabetes-home .noticias-destacadas {
  background-color: #F4F4F4;
  padding: 3rem 0;
  margin-bottom: 5rem;
}

#espacio-diabetes-home .noticias-destacadas .notes {
  display: flex;
}

#espacio-diabetes-home .noticias-destacadas .title h3 {
  margin-bottom: 3rem;
  color: #05A9FD;
}

#espacio-diabetes-home .noticias-destacadas .nota img {
  width: 540px;
}

#espacio-diabetes-home .noticias-destacadas .nota h4 {
  color: #0085c9;
  font-size: 1.7rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

#header-espacio-diabetes-home {
  padding-top: 1rem;
  display: flex;
}

.espacio-diabetes-home .container-description {
  background-color: #F4F4F4;
  margin-top: 0 !important;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.multi-carousel {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.espacio-diabetes-home .header .image,
.espacio-diabetes-home .header .content {
  width: 50%;
}

.espacio-diabetes-home .header .content {
  padding-left: 2rem;
  display: flex;
  align-items: center;
}

.espacio-diabetes-home .header .content h2 {
  width: 80%;
  margin-bottom: 1.7rem;
  font-weight: bold;
  font-size: 1.5rem;
}


.consejos-header .content p {
  width: 70%;
  font-size: 1.3rem;
}

.espacio-diabetes-home .header .content img {
  margin-top: 2rem;
  width: 80% !important;
}

/* INTERNA ESPACIO DE DIABETES */

#espacio-diabetes-interna .nota-principal {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#espacio-diabetes-interna .nota-principal .miga-pan {
  font-size: .8rem;
  color: #9F9F9F;
  margin-bottom: .5rem;
}

#espacio-diabetes-interna .nota-principal .miga-pan a {
  color: #9F9F9F;
  text-decoration: none;
}

#espacio-diabetes-interna .nota-principal .header h3 {
  margin-bottom: 2rem;
  color: #05A9FD;
}

#espacio-diabetes-interna .nota-principal .header h3:after {
  display: flex;
  content: '';
  height: 3px;
  background: black;
  width: 100%;
  margin-top: 1rem;
  background: #6db5db;
  background: linear-gradient(to right, #6db5db 0%,#f5b563 30%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6db5db', endColorstr='#f5b563',GradientType=1 );
}

#espacio-diabetes-interna .nota-principal .fondo-azul {
  background-size: 100% 70%;
  background-repeat: no-repeat;
  padding-top: 2rem;
}

#espacio-diabetes-interna .nota-principal .nota-content {
  margin-left: 70px;
  padding-top: 3rem;
}

#espacio-diabetes-interna .nota-principal .nota-content h4 {
  color: #fff;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

#espacio-diabetes-interna .nota-principal .nota-content img {
  width: 100%;
  margin-bottom: 1rem;
  display: block;
  position: relative;
}

#espacio-diabetes-interna .nota-principal .nota-content .mobile-title {
  display: none;
}

#espacio-diabetes-interna .otras-notas {
  background-color: #F4F4F4;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#espacio-diabetes-interna .otras-notas h3 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

#espacio-diabetes-interna .otras-notas .content {
  display: flex;
  justify-content: space-between;
}

#espacio-diabetes-interna .otras-notas .nota {
  margin-top: 0;
  width: 350px;
}

#espacio-diabetes-interna .otras-notas .nota h5 {
  margin-top: 1rem;
  color: #0085c9;
}

#espacio-diabetes-interna .otras-notas .nota img {
  width: 350px;
}

/* Calendario Actividades Componente Home */

.calendario-actividades .image {
  width: 200px;
}


/* -----------calendario actividades interna------------- */

/* .carousel-control-next {
  background-image: url(../img/right-arrow.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.carousel-control-prev {
  background-image: url(../img/left-arrow.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
} */


#calendario-actividades-interna .title h3:after {
  display: block;
  content: '';
  border-bottom: solid 5px #EA1D5E;
  width: 100px;
  margin-top: .7rem;
}

.banner-actividades-desktop .actividad-slider {
  width: 50%;
}

.banner-actividades-desktop .image-container {
  width: 50%;
}

.banner-actividades-desktop .image-container img {
  max-width: initial;
  width: 100%;
}

#actividad-container .esconder-desktop {
  padding: 0;
}

#actividad-container .esconder-desktop .logo-organizador {
  margin-top: 2rem;
  margin-left: 2rem;
}

#actividad-container .esconder-desktop .actividad-slider {
  width: 50%;
  justify-content: initial !important;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

#actividad-container .esconder-desktop .image-container {
  width: 50%;
}

#actividad-container .esconder-desktop .image-container img {
  width: 100%;
  max-width: initial;
}

.actividad-slider-data .doctor-info {
  display: flex;
}

.actividad-slider-data .doctor-info img {
  width: 100px;
}

.actividad-slider-data .doctor-info h5 {
  align-self: center;
  padding-left: 15px;
}

.actividad-slider .saber-mas:hover h5,
.actividad-slider .saber-mas:hover svg path {
  color: #0085c9 !important;
  stroke: #0085c9 !important;
}

/* ------------------------ */

#numeros {
  background-color: #f4f4f4;
}

#numeros .numeros {
  margin-top: 4rem;
  padding-top: 3rem;
  background-image: url(../img/mapa-mundi.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#numeros .numero-dato {
  margin-bottom: 4rem;
}

#numeros .dato-2 .ico img {
  margin-left: auto;
}

#numeros .cifra {
  font-size: 2.3rem;
  font-weight: 600;
  letter-spacing: -0.1rem;
  margin-bottom: 0;
}

#numeros .info {
  color: #0085c9;
  font-size: 1.3rem;
}

#numeros .info .highlight {
  text-transform: uppercase;
  font-weight: 600;
}

#numeros .fuentes {
  font-size: 1rem;
  color: #0085c9;
  margin-top: 5rem;
  opacity: 0.8;
}

#numeros .fuentes li {
  font-size: 0.8rem;
  color: #333;
  margin-bottom: 0.5rem;
}

#educacion h3 {
  margin-bottom: 1.5rem;
}

#educacion p {
  font-size: 1.1rem;
}

.faq-educacion-img {
  height: 0;
  padding-bottom: 66%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#educacion a {
  color: #0085c9;
}

#educacion a:hover {
  text-decoration: underline;
}

#educacion .swiper-container-horizontal>.swiper-pagination {
  width: auto;
}

.slick-dots li button::before {

  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  border: solid 2px #0085c9;
  opacity: 1;
  color: transparent;
}

.slick-dots li.slick-active button::before {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #0085c9;
  border: solid 2px #0085c9;
  opacity: 1;
  color: transparent;
}



.slick-dots {
  left: -46.5%;
  bottom: 0px;
}

.slick-dots li {
  margin: 2px;

}


#video {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../img/tapa-video.jpg);
  height: 30rem;
  display: inherit;
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  cursor: pointer;
  -webkit-transition: background-size ease-out 0.2s;
  -moz-transition: background-size ease-out 0.2s;
  -o-transition: background-size ease-out 0.2s;
  transition: background-size ease-out 0.2s;
}

#video h4 {
  margin-bottom: 2rem;
  margin-top: -2rem;
  color: white;
}

#video img {
  -webkit-transition: transform ease-out 0.2s;
  -moz-transition: transform ease-out 0.2s;
  -o-transition: transform ease-out 0.2s;
  transition: transform ease-out 0.2s;
  background-size: 102%;
}

#video:hover img {
  transform: scale(1.1);
}

#modalVideo .modal-content {
  background: none;
  border: none;
}

#modalVideo .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

#modalVideo .modal-header {
  border: none !important;
}

#consejos {
/*  background: rgb(44, 122, 200);
  background: -moz-linear-gradient(142deg,
      rgba(44, 122, 200, 1) 0%,
      rgba(90, 193, 221, 1) 100%);
  background: -webkit-linear-gradient(142deg,
      rgba(44, 122, 200, 1) 0%,
      rgba(90, 193, 221, 1) 100%);
  background: linear-gradient(142deg,
      rgba(44, 122, 200, 1) 0%,
      rgba(90, 193, 221, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#2c7ac8", endColorstr="#5ac1dd", GradientType=1);*/
  color: #fff;
}

#consejos .separador-blanco {
  border-right: #DEDEDE solid 1px;
}

#consejos .profesionales {
  margin-bottom: 3rem;
}

#consejos .profesionales .profesional {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

#consejos .profesionales .profesional a {
  color: #fff;
  font-size: 0.8rem;
}

#consejos .profesionales .profesional a:hover {
  text-decoration: underline;
}

#consejos .swiper-container {
  padding-left: 5%;
  padding-right: 5%;
}

#consejos .sw-consejo {
  transition: transform 0.2s;
}

#consejos .sw-consejo:hover {
  transition: transform 0.2s;
  transform: scale(1.1);

}

.sw-consejos .sw-consejo {
  background-color: #fff;
  height: 28rem;
  position: relative;
  width: 30rem;
  z-index: 1000;
}

.sw-consejos .sw-consejo .cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 154px;
}

.sw-consejos .sw-consejo:hover .cover.gradient:before {
  opacity: 0.2;
}

.sw-consejos .sw-consejo .data {
  padding: 25px;
  position: relative;
}

.sw-consejos .sw-consejo .data hr {
  border: 0;
  height: 3px;
  width: 75px;
  background-color: #f5b563;
  margin-left: 0;
  -webkit-transition: width ease-out 0.2s;
  -moz-transition: width ease-out 0.2s;
  -o-transition: width ease-out 0.2s;
  transition: width ease-out 0.2s;
}

.sw-consejos .sw-consejo:hover .data hr {
  width: 100px;
}

.sw-consejos .sw-consejo .data h5 {
  color: #0085c9;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.sw-consejos .sw-consejo .data p {
  color: #333333;
}

.sw-consejos .sw-consejo .mas {
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 30px;
  height: 30px;
}

#consejos-header {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
}

#consejos-header.consejos-header .fondo-gradient-blue, #consejos{
  background-image: url(../img/fondo-degrade.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.consejos-header-title h6 {
  margin-top: 3rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.consejos-header-title h6:after {
  display: flex;
  content: "";
  border-top: .3rem solid #EA1D5E;
  width: 5rem;
  transform: translateY(.5rem);
}

.consejos-header .image,
.consejos-header .content {
  width: 50%;
}

.consejos-header .image img {
  width: 100%;
}

.consejos-header .content {
  padding-left: 2rem;
  display: flex;
  align-items: center;
}

.consejos-header .content h2 {
  text-transform: uppercase;
  margin-bottom: 1.7rem;
  font-weight: bold;
  font-size: 1.5rem;
}

.consejos-header .content h2:before {
  display: flex;
  content: "";
  border-top: .1rem solid #ffffff;
  width: 4rem;
  transform: translateY(-.5rem);
}

.consejos-header .content p {
  width: 70%;
  font-size: 1.3rem;
}

.consejos-header .content img {
  margin-top: 2rem;
  width: 70%;
}

#consejos .swiper-scrollbar {
  display: none;
}

/* TIPS COMPONENTES */

#mqgtips {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #f4f4f4;
}

#mqgtips .bombilla {
  margin-bottom: 1rem;
  display: none;
}

#mqgtips .tip {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#mqgtips .image {
  width: 30%;
  max-width: max-content;
}

#mqgtips .image img {
  max-width: initial;
  width: 60px;
  height: inherit;
}

#mqgtips .content p {
  padding-right: 5%;
  padding-left: 5%;
  font-size: 1rem;
  width: 90%;
}

#tips {
  background-image: url(../img/fondo-tips.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  color: #fff;
}

#tips:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background-color: rgba(28, 47, 67, 0.67);
  width: 100%;
  height: 100%;
  z-index: 0;
}

#tips .tip {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}

#tips .tip img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

#tips .tip p {
  font-size: 1.2rem;
}

section.header-interna {
  padding-top: 5rem;
  padding-bottom: 4rem;
}

section.header-interna h6 {
  color: #0085c9;
}

section.header-interna h1 {
  font-size: 2.3rem;
}

/* FAQ */

.faq-diabetes-main {
  background-color: #0085c9;
  color: #fff;
  padding-top: 8rem;
  padding-bottom: 5rem;
  position: relative;
}

.faq-diabetes-main h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.faq-diabetes-main .texto {
  columns: 2;
}

.faq-diabetes-main .img {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  width: 150%;
  margin-left: -50%;
  left: 0;
  background-position: center;
  background-size: cover;
  margin-top: 2rem;
}

.faq-diabetes-main .compartir {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
}

.faq-diabetes-main .compartir .botones {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.faq-diabetes-main .compartir .botones a {
  margin-right: 15px;
  max-width: 20px;
}

.faq-diabetes-main .compartir .botones a img {
  opacity: 0.8;
}

.faq-diabetes-main .compartir .botones a:hover img {
  opacity: 1;
}

.faq-diabetes-main .texto p {
  margin-bottom: 1rem;
}

.faq-diabetes-main p.referencias {
  font-size: 0.8rem;
  padding-top: 1rem;
  margin-top: 2rem;
  border-top: solid 1px #fff;
  margin-bottom: 0;
}

.faq-diabetes-main ol li {
  overflow-wrap: break-word;
}

.faq-diabetes-main ol {
  font-size: 0.8rem;
  padding: 1rem;
}

.faq-diabetes-grilla h3 {
  text-transform: uppercase;
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.faq-diabetes-grilla .articulo {
  margin-bottom: 4rem;
}

.faq-diabetes-grilla .articulo .imagen {
  height: 0;
  padding-bottom: 66%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 0.75rem;
}

.faq-diabetes-grilla .articulo .gradient:before,
#educacion .gradient:before {
  opacity: 0.5;
}

.faq-diabetes-grilla .articulo:hover .gradient:before,
#educacion a:hover .gradient:before {
  opacity: 0;
}

.faq-diabetes-grilla .articulo h4 {
  color: #0085c9;
  margin-bottom: 1rem;
}

.faq-diabetes-grilla .articulo .leer-mas {
  font-size: 0.9rem;
  font-weight: 600;
  color: #0085c9;
  text-align: right;
  margin-top: 1rem;
  float: right;
}

/* DIETAS */

section#dmc {
  padding-bottom: 2rem;
  padding-top: 3rem;
}

#dmc .dietas {
  margin-bottom: 5rem;
  position: relative;
}

#dmc .dietas h3,
#ejercicio-fisico h3 {
  display: block;
  position: relative;
  margin-bottom: 1rem;
  font-weight: 400;
}

#ejercicio-fisico h3:before {
  display: flex;
  content: "";
  border-top: .2rem solid #05A9FD;
  width: 5rem;
  transform: translateY(-.5rem);
}

#dmc .dietas h3:after,#ayc h3:after{
  display: flex;
  content: "";
  background: linear-gradient(to right,  #6db5db 0%,#f5b563 50%);
  height: 5px;
  width: 50%;
  /*transform: translatey(5rem);*/
  margin-top: 0.5rem;
}

#ejercicio-fisico h3.text-white:before,
#tips h3:before,
#ejercicio-fisico main h3:before {
  background-color: #fff;
}

.dietas .swiper-slide {
  color: #05A9FD;
  text-align: center;
  display: flex;
}

.dietas .swiper-slide img {
  margin-bottom: 1rem;
  max-width: initial;
  margin-right: 1rem;
}

.dietas .swiper-slide .content {
  text-align: left;
}

.dietas .swiper-slide h4 {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  max-width: 220px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: initial;
}

.dietas .swiper-slide p {
  color: #333;
  max-width: 100%;
}

.dietas .swiper-scrollbar {
  display: none;
}

/* OTROS CONSEJOS */

#otros-consejos {
  background-color: #fff;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#otros-consejos .otros-consejos .consejos {
  display: flex;
  margin-top: 2rem;
}

#otros-consejos .otros-consejos .consejos .consejo {
  display: flex;
  /*margin-right: 2rem;*/
  transition: transform 0.2s;
  cursor: pointer;
}

#otros-consejos .otros-consejos .consejos .consejo:hover {
  transition: transform 0.2s;
  transform: scale(1.1);
}

#otros-consejos .otros-consejos .consejos .consejo:hover h4 {
  color: #0085C9 !important;
}

#otros-consejos .otros-consejos .consejos .consejo:hover svg path {
  stroke: #fc9d4d;
}


#otros-consejos .otros-consejos .consejos .consejo .image {
  width: 50%;
}

#otros-consejos .otros-consejos .consejos .consejo .image img {
  width: 300px;
  height: 185px;
}

#otros-consejos .otros-consejos .consejos .consejo .content {
  width: 50%;
  padding-left: 1rem;
}

#otros-consejos .otros-consejos .consejos .consejo .content #oc-title {
  color: #05A9FD;
  font-size: 1.3 !important;
}

#otros-consejos .otros-consejos .consejos .consejo .content p {
  color: #707070;
  font-size: .8rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

/* --------------- */

.sidebar {
  padding-top: 3rem;
}

.sidebar h6 {
  position: relative;
  padding-left: 40px;
  margin-bottom: 1.5rem;
}

.sidebar h6:before {
  position: absolute;
  content: '';
  border: solid 1px #000;
  left: 0;
  width: 30px;
  top: 8px;
  height: 1px;
}

.sidebar .seccion {
  margin-bottom: 2.5rem;
}

.sidebar .img {
  height: 0;
  padding-bottom: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 1rem;
}

.sidebar p {
  font-size: 0.9rem;
}

#ayc{
  background-color: #F4F4F4;
}

#ayc h3 {
  position: relative;
  margin-bottom: 4rem;
}


#ayc .opciones {
  position: relative;
}

#ayc .opcion-hdc .union {
  position: absolute;
  bottom: -180px;
  left: 0;
  width: auto;
}

#ayc .opcion-veg .union {
  position: absolute;
  top: 0;
  left: -225px;
  width: auto;
  height: 100%;
}

#ayc .opcion-pro .union {
  position: absolute;
  bottom: 0;
  left: -270px;
  width: auto;
  height: 100%;
}

#ayc .plato {
  float: right;
  margin-right: 3rem;
  margin-top: 2rem;
}

#ayc .opciones p.titulo {
  font-weight: 600;
  color: #0085c9;
  font-size: 1.2rem;
}

#ayc .opciones p {
  margin-bottom: 0.5rem;
}

#ayc .opciones ul {
  list-style-type: '- ';
  column-count: 2;
  padding: 0;
}

/* CONTACTO */



#contacto h3:before {
  display: flex;
  content: "";
  border-top: .2rem solid #fff;
  width: 5rem;
  transform: translateY(-.5rem);
}

.formulario label {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 100%;
  margin-bottom: 2rem;
}

.formulario input,
.formulario textarea {
  border: none;
  background-color: #fff;
  min-height: 30px;
  color: black;
  padding: 5px;
  border-radius: 8px;
}

.formulario button {
  border: none;
  background-color: #fff;
  padding: 5px 30px;
  border-radius: 50px;
  float: right;
  box-shadow: inset 0px 0px 0px 2px #fff !important;
  -webkit-transition: all ease-out 0.2s;
  -moz-transition: all ease-out 0.2s;
  -o-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
}

.formulario button:hover {
  background: none;
  color: #fff;
}

#contacto .reportar p {
  font-size: 1.5rem;
  text-align: center;
  max-width: 570px;
  margin: auto;
}

.reportar {
  padding-bottom: 0;
  padding-top: 0;
}

.reportar .reportar-container {
  display: flex;
}

.reportar .reportar-container .content {
  width: 50%;
  align-self: center;
  padding-right: 5%;
  padding-left: 5%;
}

.reportar .reportar-container .image {
  width: 50%;
}

.reportar .reportar-container .image img {
  width: 100%;
}


/* EJERCICIO FÍSICO */

#ejercicio-fisico main .texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding: 3rem 15% 3rem 5%;
}

#ejercicio-fisico main h3 {
  margin-bottom: 2rem;
}

#ejercicio-fisico main h4 {
  font-weight: 300;
  line-height: 1.5;
}

#ejercicio-fisico h3 {
  margin-bottom: 4rem;
}

/* EJERCICIO PARA TODOS */

section#para-todos {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

section#para-todos h3 {
  display: block;
  position: relative;
  margin-bottom: 2rem;
  font-weight: 400;
}

section#para-todos .para-todos-content .image {
  width: 30%;
  max-width: max-content;
  padding-right: 15px;
}

section#para-todos .para-todos-content .tip {
  margin-top: 3rem;
}

section#para-todos .para-todos-content .image img {
  width: auto;
  height: auto;
  max-width: fit-content;
}

section#para-todos .para-todos-content .content {
  padding-left: 15px;
}

section#para-todos .para-todos-content .content h4 {
  color: #05A9FD;
  font-weight: bold;
  margin-bottom: 2rem;
}

section#para-todos h3:before {
  display: flex;
  content: "";
  border-top: .2rem solid #05A9FD;
  width: 5rem;
  transform: translateY(-.5rem);
}


.sw-paratodos .swiper-slide,
.sw-consultar .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  text-align: center;
}

.sw-paratodos .swiper-slide img,
.sw-consultar .swiper-slide img {
  margin-bottom: 1.5rem;
}

.sw-paratodos .swiper-slide h4 {
  text-transform: uppercase;
  max-width: 280px;
  margin-bottom: 1rem;
}

.sw-paratodos .swiper-slide p,
.sw-consultar .swiper-slide p {
  color: #0085c9;
  max-width: 320px;
}

#ejercicios .ejercicios-deporte {
  display: flex;
}

#ejercicios .ejercicios-deporte .etapa img {
  align-self: start;
}

#ejercicios .ejercicios-deporte .etapa p {
  align-self: start;
}

#ejercicios h3 {
  display: block;
  position: relative;
  margin-bottom: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

#ejercicios h3:before {
  display: flex;
  content: "";
  border-top: .2rem solid #fff;
  width: 5rem;
  transform: translateY(-.5rem);
}

#ejercicios ol.etapas {}

#ejercicios .etapa {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 2rem;
}

#ejercicios .etapa p {
  font-size: 1.3rem;
  line-height: 1.3;
}

#ejercicios .etapa p.numero {
  font-size: 5rem;
  line-height: 0.9;
  margin-right: 1rem;
  color: #3ab6f5;
  font-weight: 600;
}

#frecuencia h3 {
  display: block;
  position: relative;
  margin-bottom: 2rem;
  font-weight: 400;
  text-transform: uppercase;
}

#frecuencia h3:before {
  display: flex;
  content: "";
  border-top: .2rem solid #05A9FD;
  width: 5rem;
  transform: translateY(-.5rem);
}

#frecuencia .highlight {
  display: flex;
  align-items: center;
}

#frecuencia .highlight p.numero {
  font-size: 4rem;
  margin-right: 1rem;
}

#frecuencia .highlight p.texto {
  font-size: 1.3rem;
  text-transform: uppercase;
}

#aerobico {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
}

#aerobico .content {
  width: 50%;
  display: flex;
}

#aerobico .content .container {
  align-self: center;
}

#aerobico .content h3 {
  text-align: left;
  padding-left: 15px;
  margin-bottom: 3rem;
  text-transform: uppercase;
}

#aerobico .content h3:before {
  display: flex;
  content: "";
  border-top: .2rem solid #fff;
  width: 5rem;
  transform: translateY(-1rem);
}

#aerobico .content p {
  text-align: left;
}

#aerobico .image {
  width: 50%;
}

#aerobico .image img {
  width: 100%;
}

#aerobico p {
  font-size: 1.3rem;
  padding: 0;
}

#aerobico .highlight {
  display: flex;
  align-items: center;
}

#aerobico .highlight p.numero {
  font-size: 6rem;
  margin-right: 1rem;
}

#aerobico .highlight p.texto {
  font-size: 1.8rem;
  text-transform: uppercase;
}

#consulta .tip {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

#consulta .tip img {
  width: 60px;
  max-width: initial;
  margin-right: 2rem;
}


/* CV */

#cv h1 {
  font-size: 1.9rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

#cv h4 {
  font-size: 1.3rem;
}

#cv h6 {
  color: #0085c9;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

/* TEST DE RIESGO  */

.test-riesgo .manos-mobile {
  display: none;
}

.test-preguntas-container {
  background-color: #F4F4F4;
  /*height: 40rem;*/
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.marca{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  flex-wrap: wrap;
  padding-left: 50px;
  margin-top: 2em;
}

.marca img{
  max-width: 300px;
}

.buttons div{
  display: flex;
  flex-wrap: wrap;
}

.barra-progreso {
  background: rgb(0, 133, 201);
  background: linear-gradient(90deg, rgba(0, 133, 201, 1) 0%, rgba(6, 48, 171, 1) 100%);
}

.test-preguntas-container .modal-test {
  margin-bottom: 2rem;
  display: flex;
}

.test-preguntas-container .modal-test img {
  width: 50px;
  height: 50px;
}

.test-preguntas-container .modal-test p {
  color: #05A9FD;
  align-self: center;
  margin-left: .5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.test-preguntas-container .inputs {
  padding-left: 50px;
  margin-top: 1rem;
}

.test-preguntas-container .container-inputs li {
  list-style-type: none;
  cursor: pointer;
  color: #05A9FD;
  margin: .5rem 0;
  font-size: 1.2rem;
}

.test-preguntas-container .container-inputs .title {
  text-transform: uppercase;
}

.test-preguntas-container .container-inputs .subtitle {
  font-size: 1.2rem;
  color: #05A9FD;
  font-weight: bold;
}

.test-preguntas-container .container-inputs .calculadora-container label {
  font-size: 1.2rem;
  color: #05A9FD;
  display: grid;
}

.test-preguntas-container .container-inputs .calculadora-container .calculadora input {
  width: 50%;
}

.test-preguntas-container .container-inputs .calculadora-container .calculadora-resultado {
  transition: opacity 0.2s ease;
}

.test-preguntas-container .container-inputs .calculadora-container .calculadora-resultado span {
  font-size: 2rem;
  font-weight: bold;
}

.test-preguntas-container .container-inputs .calculadora-container .calculadora-resultado p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0085c9;
}

.test-preguntas-container .container-inputs .calculadora-container .show {
  opacity: 1;
}

.test-preguntas-container .container-inputs .calculadora-container .hide {
  opacity: 0;
}

.test-preguntas-container .container-inputs .disabled {
  display: none;
}

.test-preguntas-container .container-inputs .active {
  color: #EA1D5E;
  font-size: 1.2rem;
  font-weight: bold;
  padding-left: 50px;
}


.test-preguntas-container .container-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container-label:hover input~.checkmark {
  background-color: #ccc;
}

/* .container-label input:checked~.checkmark {
  background-color: #19bfb9;
} */

.container-label input:checked~.checkmark:after {
  display: block;
}

.test-preguntas-container .container-inputs .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #fff;
}

.test-preguntas-container .container-inputs .checkmark:after {
  content: "";
  background-color: #0085c9;
  position: absolute;
  display: none;
  top: 6px;
  left: 6px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.test-preguntas-container .container-buttons {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
  margin-top: 3rem;
}

.test-preguntas-container .container-buttons p {
  align-self: center;
}

.test-preguntas-container .container-buttons button {
  width: 15em;
  height: 3em;
  color: #05A9FD;
  background-color: white;
  border-radius: 35px;
  border: solid 2px #05A9FD;
}


.test-preguntas-container .container-buttons button:hover {
  width: 15em;
  height: 3em;
  color: white;
  background-color: #05A9FD;
  border-radius: 35px;
  border: solid 2px white;

}

.resultado-container {
  background-color: #F4F4F4;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.resultado-container span {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-left: 1rem;
  font-weight: normal;
}

.resultado-container .su-resultado {
  color: #05A9FD;
  margin-bottom: 2rem;
  font-weight: bold;
}

.resultado-container h4 {
  color: #05A9FD;
  margin-bottom: 4rem;
  font-weight: bold;
}

.resultado-container .text {
  margin-bottom: 2rem;
}

.resultado-container .buttons {
  display: flex;
  justify-content: space-between;
}

.resultado-container .buttons a {
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
}

.resultado-container .buttons a img {
  margin-right: 1rem;
}

.compartir-resultado-container {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.compartir-resultado-container h4 {
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
}

.compartir-resultado-container .container-images {
  background-image: url(../img/image-test.png);
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
  height: 80px;
  margin-top: 6rem;
}

a.btn{
  width: 20em;
  height: auto;
  color: #05A9FD;
  background-color: white;
  border-radius: 35px;
  border: solid 2px #05A9FD;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* FOOTER */

section#sociedades {
  padding-top: 4rem;
  padding-bottom: 2rem;
}

#sociedades .logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

#sociedades .logos .logo {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
  margin-bottom: 2rem;
}

#sociedades .logos .logo p.nombre {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.7rem;
  max-width: 100%;
}

#sociedades .logos .logo p.direccion {
  font-size: 0.8rem;
  max-width: 100%;
}


.sociedades-img img {
  max-height: 60px;
  max-width: 205px;
  margin-bottom: 1rem;
}

section.wikicardio {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.wikicardio img {
  max-width: 220px;
}

.wikicardio h5 {
  font-size: 1.1rem;
}

.mapa-footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 0.9rem;
}

.mapa-footer ul li {
  margin-bottom: 0.1rem;
  margin-top: 0.2rem;
}

.mapa-footer li a {
  font-size: 0.9rem;
  color: #fff;
  -webkit-transition: color ease-out 0.2s;
  -moz-transition: color ease-out 0.2s;
  -o-transition: color ease-out 0.2s;
  transition: color ease-out 0.2s;
}

.mapa-footer li a img {
  opacity: 1;
  width: 25px;
  height: 25px;
  -webkit-transition: all ease-out 0.2s;
  -moz-transition: all ease-out 0.2s;
  -o-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
}

.mapa-footer li a:hover img {
  filter: opacity(0.7);
}

.mapa-footer li a:hover {
  text-decoration: underline;
}

.footer-bottom .qr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-bottom .qr a {
  width: 110px;
  height: 110px;
}

.footer-bottom .disclaimer {
  font-size: 0.8rem;
}

.footer-bottom .submenu {
  text-align: left;
}

.footer-bottom .submenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}



.footer-bottom .submenu a {
  color: #333;
  font-size: 0.7rem;
  margin-left: 2em;
  margin-right: 2em;
}

.footer-bottom .submenu a:hover {
  color: #0085c9;
}

.footer-bottom .footer-mobile {
  display: none;
}

.bg-blue{
  background-color: #0085C9 !important;
}

/* CAROUSEL RESPONSIVE */

.carousel-responsive {
  display: none;
}


/* ------------------ */


@media (min-width: 768px) {
  #h-main .carousel-item .golosina.desktop {
    display: block;
    height: 90%;
  }

  #h-main .carousel-item .golosina.mobile {
    display: none;
  }

  .sw-consejos .swiper-wrapper,
  .dietas .swiper-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .sw-consejos .sw-consejo {
    width: 45%;
    margin-right: 1em;
  }

  #dmc .dietas .swiper-container,
  .sw-paratodos .swiper-wrapper,
  .sw-consultar .swiper-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .sw-paratodos .swiper-wrapper .swiper-slide,
  .sw-consultar .swiper-wrapper .swiper-slide {
    width: 48%;
  }

  #dmc .dietas .dieta-nombre {
    position: absolute;
    top: 0;
    left: -4rem;
    background: none;
  }

  .dietas .swiper-slide {
    width: 48%;
  }

  #modalVideo .modal-dialog {
    max-width: 900px;
  }

  .necesitas-saber p:last-of-type{
    max-width: 350px;
    margin-left: auto;
  }
}

/*  MOBILE */

@media (max-width: 768px) {


  #sociedades .slick-slider {
    display: flex;
  }

  #consejos .separador-blanco {
    border-right: #DEDEDE solid 0px;
  }

  .sw-consejos .sw-consejo {

    background-color: #fff;
    height: 28rem;
    position: relative;
    width: 22rem;
  }

  .shares-buttons {
    padding-top: 0 !important;
  }

  .container-button-compartir-espacio-diabetes {
    display: initial;
    justify-content: start;
    position: relative;
    right: 0;
    left: 0;
    top: 2rem;
    margin-bottom: 0;
  }

  .container-button-compartir {
    justify-content: flex-start;
  }

  .container-button-compartir p {
    font-size: 1rem !important;
    align-self: center;
  }

  .calendar {
    padding-left: 0 !important;
  }

  .pull-down {
    padding-top: 10em;
  }


  .desktop {
    display: none;
  }

  .mobile {
    display: inline;
  }

  .sw-consejos .swiper-wrapper,
  .dietas {
    display: grid;
  }

  .sw-consejo {
    margin-bottom: 30px;
  }

  .grid-mobile {
    display: grid;
  }

  .fixed-wrapper {
    top: 2%;
  }

  .reverse-mobile {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .reverse-row-mobile {
    display: flex;
    flex-direction: row-reverse !important;
  }

  h3 {
    font-size: 1.5rem;
  }

  .menu-principal {
    width: 100%;
    padding: 2rem;
  }

  .header-right .enfermeria {
    display: none;
  }

  .menu-slider-right li a {
    font-size: 1.5rem;
  }

  section {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  section.header-interna h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  header.seccion h2 {
    font-size: 1.7rem;
  }

  /* HOME BANNER SLIDER */

  #h-main .carousel-item {
    align-items: flex-end;
  }

  #h-main .carousel-item .golosina.desktop {
    display: none;
  }

  #h-main .carousel-item .golosina.mobile {
    display: block;
  }

  #h-main .fixed-button button {
    bottom: 10px;
    right: 10px;

  }

  #h-main .carousel-inner .text {
    width: 85%;
    margin: auto;
    margin-top: 50px;
  }

  #h-main .carousel-inner .text span.tag {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  #h-main .carousel-inner .text h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  #h-main .carousel-inner .text button {
    width: 100%;
    /*margin-bottom: -6rem;*/
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none;
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }



  /**/

  #home-selector .iconos {
    text-align: -webkit-center;
    margin-top: 1rem;
  }

  #numeros .numeros {
    margin-top: 2rem;
    padding-top: 1rem;
  }

  #numeros .cifra {
    font-size: 1.8rem;
    text-align: center;
  }

  #numeros .info {
    margin-bottom: 1rem;
    text-align: center;
  }

  #numeros .dato-2 .ico img {
    margin: auto;
  }

  #video {
    height: 20rem;
  }

  /* TODO LO QUE NECESITAS SABER SECTION */



  .necesitas-saber h6 {
    font-weight: bold;
  }

  .necesitas-saber .images-mobile {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .necesitas-saber .texto-animacion {
    text-align: initial;
    padding-left: 0px;
  }



  /*SECCION TU ESPACIO DE DIABETES EN HOME */

  #espacio-diabetes-in-home .title-notas {
    display: none;
  }

  #espacio-diabetes-in-home .desktop-notes {
    display: none;
  }

  #espacio-diabetes-in-home .title-note {
    display: flex;
    width: 100%;
    justify-content: inherit;
  }

  #espacio-diabetes-in-home .title-note h3 {
    font-size: 1.3rem;
    align-self: flex-end;
  }

  #espacio-diabetes-in-home .title-note svg {
    display: initial;
  }

  #espacio-diabetes-in-home .title-notas-mobile {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom: #DEDEDE solid 1px;
    padding-bottom: 1rem;
  }

  #espacio-diabetes-in-home .title-notas-mobile img {
    height: fit-content;
    width: 30px;
    margin-right: .5rem;
  }

  #espacio-diabetes-in-home .title-notas-mobile h3 {
    color: #05A9FD;
  }


  .multi-desktop {
    display: none;
  }

  /* ---------------------- */

  /* NUMEROS RIESGO CONTAINER */

  .numeros-riesgo-container {
    display: initial;
  }

  /* ---------------------- */

  /*  VIDEO  */

  #container-video {
    width: 100%;
  }

  /* ----------- */

  /* NUMEROS RIESGO CONTAINER */

  #numeros-slider {
    width: 100%;
    padding: 4rem 15px;
  }

  #numeros-slider-fuentes {
    width: 100%;
    height: 100vh;
  }

  /* ---------------------- */

  /* HOME ESPACIO DE DIABETES */

  #espacio-diabetes-home {
    padding-top: 3rem;
  }

  #espacio-diabetes-home .nota-principal {
    margin-top: 5rem;
  }

  #espacio-diabetes-home .nota-principal h3 {
    color: #0085c9;
  }

  #espacio-diabetes-home .nota-principal img {
    max-width: -webkit-fill-available;
  }

  #espacio-diabetes-home .detail h4 {
    font-size: 1.4 !important;
  }

  #espacio-diabetes-home .detail {
    padding-left: 0;
    margin-top: 1rem;
  }

  #espacio-diabetes-home .otras-notas .content {
    display: none;
  }

  #espacio-diabetes-home .otras-notas .nota {
    margin-bottom: 2rem;
    width: auto;
  }

  #espacio-diabetes-home .noticias-destacadas .notes {
    display: none;
  }

  #espacio-diabetes-home .noticias-destacadas .notes .nota {
    margin-bottom: 2rem;
  }


  #header-espacio-diabetes-home {
    display: initial;
  }

  .espacio-diabetes-home .header .image,
  .espacio-diabetes-home .header .content {
    width: 100%;
  }


  #espacio-diabetes-home .nota-principal .content {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  /* INTERNA ESPACIO DE DIABETES */

  #espacio-diabetes-interna .nota-principal {
    margin-top: 5rem;
  }

  #espacio-diabetes-interna .nota-principal .nota-content {
    margin-left: 0;
  }

  #espacio-diabetes-interna .nota-principal .nota-content .desktop-title {
    display: none;
  }

  #espacio-diabetes-interna .nota-principal .nota-content .mobile-title {
    display: initial;
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  #espacio-diabetes-interna .nota-principal .nota-content p {
    margin-top: 2rem;
  }

  #espacio-diabetes-interna .nota-principal .header h3 {
    color: #0085C9;
  }

  #espacio-diabetes-interna .otras-notas .content {
    display: none;
  }

  #espacio-diabetes-interna .otras-notas .nota {
    width: auto;
    margin-bottom: 2rem;
  }


  /* ------------------------ */

  div .slick-dots {
    margin-left: 0;
  }


  .slick-next {
    display: none !important;
  }

  .derecha {
    display: initial !important;
  }

  .faq-educacion-img {
    margin-bottom: 2rem;
    padding-bottom: 66%;
  }

  #educacion p {
    font-size: 1rem;
  }

  /* CONSEJOS INTERNAS */

  #consejos-header {
    display: initial;
  }

  .consejos-header .image {
    width: initial;
  }

  .consejos-header .content {
    width: initial;
    padding: 3rem 1rem;
  }


  .consejos-header-title h6 {
    margin-bottom: 2rem;
  }

  .consejos-header .content h2 {
    font-size: 1.2rem;
    margin-top: 2rem;
  }

  .consejos-header .content p {
    font-size: 1rem;
    width: initial;
  }

  .consejos-header .content img {
    width: initial;
  }

  #consejos .profesionales .profesional {
    margin-bottom: 1.5rem;
  }

  #consejos .swiper-scrollbar {
    display: block;
    background-color: #1194b8;
    height: 10px;
    top: 10px;
    width: 100%;
  }

  #consejos .swiper-scrollbar-drag {
    background-color: #fff;
  }

  /* COLACION ALMUERZO CENA */

  section#dmc {
    padding-bottom: 2rem;
    padding: 2rem 1rem;
  }


  /* OTROS CONSEJOS */

  #otros-consejos .otros-consejos {
    padding-right: 15%;
    padding-left: 15%;
  }

  #otros-consejos .otros-consejos .consejos {
    display: initial;
  }

  #otros-consejos .otros-consejos h3 {
    margin-bottom: 2rem;
  }

  #otros-consejos .otros-consejos .consejos .consejo {
    display: initial;
  }

  #otros-consejos .otros-consejos .consejos .consejo .content {
    background-color: white;
    width: initial;
  }

  #otros-consejos .otros-consejos .consejos .consejo .image {
    width: initial;
  }

  #otros-consejos .otros-consejos .consejos .consejo .image img {
    width: 100%;
  }

  #otros-consejos .otros-consejos .consejos .consejo .content #oc-title {
    padding-top: 1rem;
  }

  #otros-consejos .otros-consejos .consejos .consejo .content img {
    padding-bottom: 1rem;
  }

  #ayc h3 {
    margin-bottom: 2rem;
  }

  #ayc .opciones {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  #ayc .opciones .union {
    display: none !important;
  }

  #ayc .plato {
    float: inherit;
    margin: auto;
    margin-bottom: 2rem;
  }

  .sw-paratodos .swiper-slide,
  .sw-consultar .swiper-slide {
    margin-bottom: 40px;
  }

  .overide-swiper-wrapper {
    display: grid;
  }

  #tips .tip p {
    font-size: 1rem;
  }

  #tips .tip.reverse-row-mobile {
    text-align: right;
  }

  #tips .tip.reverse-row-mobile img {
    margin-left: 20px;
    margin-right: 0;
  }

  section.wikicardio {
    text-align: center;
  }

  .wikicardio img {
    margin: auto;
    max-width: 250px;
    margin-bottom: 2rem;
  }

  /* TEST RIESGO MOBILE */

  .test-riesgo .manos {
    display: none;
  }

  .test-riesgo .manos-mobile {
    display: initial;
    margin-bottom: 2rem;
  }

  .test-preguntas-container {
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .test-preguntas-container .container-buttons {
    width: 100%;
  }

  .test-preguntas-container .container-buttons p {
    text-align: -webkit-center;
  }

  .test-preguntas-container .container-inputs .modal-test {
    text-align: -webkit-center;
  }

  .test-preguntas-container .modal-test img {
    margin-bottom: 1rem;
  }

  .test-preguntas-container .modal-test p {
    font-weight: bold;
  }

  .resultado-container .buttons {
    display: initial;
  }

  .resultado-container .buttons a {
    margin-bottom: 1rem;
  }

  .compartir-resultado-container h4 {
    padding: 0;
    margin-bottom: 1rem;
  }

  /* ------------------ */

  section.header-interna {
    padding-bottom: 2rem;
  }

  .faq-diabetes-main {
    padding-top: 4rem;
  }

  .faq-diabetes-main .img {
    width: 100%;
    margin: auto;
    padding-bottom: 66%;
    margin-top: 2rem;
  }

  .faq-diabetes-main .texto {
    column-count: inherit;
    margin-top: 1.5rem;
  }

  .faq-diabetes-main .compartir {
    border-bottom: solid 1px #fff;
    padding-bottom: 1.5rem;
    align-items: center;
    margin-top: 1rem;
  }

  .faq-diabetes-main .compartir h6 {
    margin-bottom: 1rem;
  }

  .faq-diabetes-main .botones a {
    margin-left: 10px;
    margin-right: 10px;
  }

  #dmc .dietas .dieta-nombre {
    display: none;
  }

  #ejercicios .ejercicios-deporte {
    display: initial;
  }

  #ejercicio-fisico main .texto {
    padding: 3rem 1rem 3rem 1rem !important;
  }

  #ejercicio-fisico h3 {
    margin-bottom: 2rem;
  }

  #ejercicio-fisico h4 {
    font-size: 1.25rem;
  }

  #ejercicios .etapa p.numero {
    font-size: 3.8rem;
  }

  #aerobico {
    display: initial;
  }

  #aerobico .content {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #aerobico .image {
    width: 100%;
  }

  #aerobico p,
  #ejercicios .etapa p {
    font-size: 1.1rem;
  }


  #frecuencia img {
    margin-bottom: 1rem;
  }

  .dietas .swiper-scrollbar,
  #ejercicio-fisico .swiper-scrollbar {
    display: block;
    width: 100%;
    height: 10px;
    margin-top: 2rem;
    background-color: #b9dbe5;
  }

  .dietas .swiper-scrollbar .swiper-scrollbar-drag,
  #ejercicio-fisico .swiper-scrollbar .swiper-scrollbar-drag {
    background-color: #0085c9;
  }

  .sidebar {
    display: none;
  }

  #sociedades .logos .logo {
    width: 100%;
    display: flex;
  }

  #sociedades .logos .logo .enlace img {
    max-width: 100%;
  }

  #sociedades .enlace {
    margin-left: 100em;
  }

  .mapa-footer ul {
    margin-bottom: 2rem;
  }

  .footer-top .qr {
    bottom: -6rem;
    left: calc(50% - 55px);
  }

  .footer-bottom .qr {
    justify-content: center;
    padding-top: 2rem;
  }

  .footer-bottom {
    text-align: center;
    padding-top: 0 !important;
  }

  .footer-bottom .footer-desktop {
    display: none !important;
  }

  .footer-bottom .footer-mobile {
    display: initial;
  }

  .footer-bottom .footer-mobile a {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .footer-bottom .footer-mobile .submenu {
    margin-bottom: 3rem;
  }

  .footer-bottom .footer-mobile .az-logo {
    display: flex;
    justify-content: center;
  }

  .footer-bottom .footer-mobile .az-logo img {
    width: 150px;
  }

  .footer-bottom .disclaimer {
    margin-top: 3rem;
  }

  .footer-bottom .submenu {
    text-align: center;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    row-gap: 20px;
  }

  .footer-bottom .submenu a {
    margin: 0;
    text-align: left;
  }

  .carousel-responsive {
    display: initial;
  }

  .carousel-responsive .slick-list {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .carousel-responsive .nota {
    margin-top: 0;
    width: 350px;
    padding: 15px;
    border: #E8E8E8 solid 1px;
    box-shadow: #E8E8E8 1px 1px 1px 1px;
  }

  .carousel-responsive .nota .mas-info {
    display: flex;
    justify-content: flex-end;
  }

  .carousel-responsive .nota .mas-info svg {
    width: 20px;
  }

  .carousel-responsive .nota .mas-info p {
    align-self: center;
    margin-left: .5rem;
    color: #0085c9;
  }

  .carousel-responsive .nota h5 {
    margin-top: 1rem;
    color: #0085c9;
    height: 30px;
  }

  .carousel-responsive .nota img {
    width: 100%;
  }

  #contacto h3 {
    margin-bottom: 2rem;
  }

  .reportar .reportar-container {
    display: flex;
    flex-flow: column-reverse;
  }

  .reportar .reportar-container .content,
  .reportar .reportar-container .image {
    width: 100%;
  }

  .reportar .reportar-container .content {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

}

.container-banner {
  margin-top: 3rem;
  margin: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}



.contacto .disabled {
  display: none;
}

.contacto .active {
  color: red;
  font-size: 1rem;
}

.enfermeria-not {
  opacity: 0;
}

.each-fade>div {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

div .flextips {
  display: grid !important;
}

div .col-md-3 .tip {
  display: inline !important;
}


#mqgtips div .slick-dots {
  left: 0;
  bottom: -3rem;
}


p .event-author {
  color: #0085c9;
  margin-left: 1em;
}

.event-title {
  color: #0085c9;
}

.event-desc {
  color: #0085c9;
}

.derecha {
  display: inline-flex;
}

.eventos-negro-celeste {
  color: #333333;
}

.actividad{
  width: 100%;
}

.eventos-negro-celeste:hover {
  color: #05A9FD;
}

.apoyo {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.apoyo .logos {
  display: flex;
  align-items: center;
}

.apoyo .logos a {
  margin-left: 2rem;
  margin-right: 2rem;
}

#actividades-slider .slick-slide {
  width: 1080;
}


.carousel-control-next-icon,
.carousel-control-prev-icon {

  color: #6BB5DB;

}

.actividad-slider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actividad-slider .logo-organizador {
  display: flex;
  justify-content: space-between;
  padding-top: 4rem;
  padding-bottom: 1rem;
}

.actividad-slider .logo-organizador img {
  height: fit-content;
  width: 65%;
}

.actividad-slider .actividad-header {
  padding-top: 2rem;
  padding-left: 2rem;
}

.actividad-slider .actividad-header p {
  font-size: 15px;
  color: #0085c9;
}

.actividad-slider h4 {
  font-size: 28px;
  margin-top: 1rem;
}

.actividad-slider .subtitle {
  padding: 0 !important;
}

.actividad-slider h5 {
  margin-top: 1em;
  font-size: 22px;
  color: #0085c9;
  padding-left: 30px;
}

.actividad-slider-data {
  width: 1140px;
}

.actividad-slider-data p {
  margin-top: 1em;
}

.actividad-slider-data h5 {
  font-size: 22px;
}

.bloque {
  width: 340px;
  margin-left: 30px;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0 !important;
}

.fake-w {
  width: 3em;
}

.compartir {
  color: #0085c9;

}

.compartir p {
  font-size: 15px;
}

.logo-organizador {
  margin-top: 5em;
  margin-right: 4em;
}

.separador-rojo {
  margin-top: 0;
  border-top: 2px solid #EA1D5E;
}

.header-actividades {
  margin-top: 5em;
}

.header-actividades .separador-rojo {
  width: 9.5em;

}


#actividad-container h4 {
  font-size: 40px;
}


#actividad-container h5 {
  font-size: 28px;
}

#actividad-container p {
  font-size: 15px;
}




.test {
  width: 700px;
  color: white;
  margin-top: 7em;
  margin-bottom: 7em;
}

.test .title h3 {
  margin-bottom: 1em;
}

.test h3 {
  margin-bottom: 0.5em;
}

.test h4 {
  margin-bottom: 0.5em;
}


.gradiente-test {
  background-image: url(../img/fondo-degrade.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}

.manos {
  width: 30%;
  height: 30%;

}

.comenzar-test {
  width: 15em;
  height: 3em;
  color: #05A9FD;
  background-color: white;
  border-radius: 35px;
  border: solid 2px #05A9FD;
}


.comenzar-test:hover {
  width: 15em;
  height: 3em;
  color: white;
  background-color: #05A9FD;
  border-radius: 35px;
  border: solid 2px white;

}



.modal-test {
  background-color: #F4F4F4;
}

.numeros {
  font-size: 28px;
  width: 30px;
  height: 30px;
  color: #05A9FD;
  background-color: white;
  border-radius: 50%;
  border: solid 2px #05A9FD;

}

.numeros p {
  margin-top: 0.5em;
}



.duo {
  width: 600px;
}

#sociedades .slick-slider {
  display: none;
}

.actividades-mobile {
  display: none !important;
}

.sw-consejo {
  width: 90% !important;
}

.actividad-sliderr {
  display: none !important;
}

.actividad-slider-data-mobile {
  display: none;
}


#actividades-relacionadas-mobile .boton-calendario {
  background-color: white;
  width: 345px;
  height: 53px;
  border: solid 1px #0085C9;
  border-radius: 35px;
  color: #0085C9;
  position: relative;
  top: -25px;
}

#actividades-relacionadas-mobile {
  display: none;
}

#compartir-evento button {
  border: none;
  margin: 0;
  background-color: #f4f4f4;
}

@media (max-width: 768px) {
  #actividades-relacionadas-mobile {
    display: inline;
  }

  .hide {
    display: none;
  }

  #sociedades .slick-slider {
    display: flex;
  }

  .logos-desktop {
    display: none !important;
  }

  .sw-consejo {
    width: 100% !important;
  }

  .actividades-mobile {
    display: grid !important;
  }

  .actividades-mobile .actividad-slider img {
    display: flex;
  }

  .actividades-mobile .actividad-slider .foto-principal {
    max-width: initial;
    width: 100%;
  }

  .actividades-mobile p {
    font-size: 15pt;
  }

  .logo-organizador {
    margin: 0;
    margin-right: 1em;
  }

  .actividad-sliderr {
    display: grid !important;
  }

  .esconder-desktop {
    display: none !important;
  }

  .actividad-slider-data-mobile {
    display: grid !important;
  }

  .bloque {
    margin-left: 1em;
  }

  .bloque p {
    font-size: 17pt;
  }

  .bloque h5 {
    font-size: 15pt;
  }


  .actividades-mobile p {
    margin: 0;
    font-size: 14px;
    margin: 1.1rem;
  }

  .actividades-mobile h4 {
    margin: 0;
    font-size: 24px;
    margin: 1rem;
  }

  .actividades-mobile h5 {
    margin: 0;
    font-size: 15px;
    margin-left: 1rem;
    padding: 0 !important;
  }

  .actvidades-mobile svg {
    margin-bottom: 3em;

  }

  .saber-mas {
    margin-left: 1em;

  }

  .saber-mas svg {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }

  .saber-mas h5 {
    margin: 0;
  }

  .bloque {
    margin-left: 0;
  }

  .necesitas-saber .animacion-iframe{
    height: 400px;
  }

}