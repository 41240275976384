@font-face {
    font-family: 'Impulsa';
    src: url('Impulsa-Regular.woff2') format('woff2'),
        url('Impulsa-Regular.woff') format('woff'),
        url('Impulsa-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

