@import url("font/stylesheet.css");

:root {
    --azul: #00A4E2;
    --celeste: #ADE1F2;
    --celeste-soft: #c8ecff;
    --naranja-oscuro: #F1E0CC;
    --naranja-soft: #F6E9D9;
    --naranja: #FB9C57;
}

#dia-mundial-diabetes .texto-azul {
    color: var(--azul);
}

#dia-mundial-diabetes .texto-naranja {
    color: var(--naranja);
}

#dia-mundial-diabetes .bg-celeste {
    background-color: var(--celeste);
}

#dia-mundial-diabetes .bg-naranja {
    background-color: var(--naranja);
}

#dia-mundial-diabetes .bg-naranja-soft {
    /*background-color: var(--naranja-soft);*/
    background: rgb(251,156,87);
    background: linear-gradient(90deg, rgba(251, 156, 87, 0.5) 5%, rgba(246,233,217,1) 5%);
}

#dia-mundial-diabetes .bg-naranja-oscuro {
    background-color: var(--naranja-oscuro);
}

#dia-mundial-diabetes .bg-celeste-soft {
    background-color: var(--celeste-soft);
}

#dia-mundial-diabetes .bg-azul {
    background-color: var(--azul);
}

#dia-mundial-diabetes .impulsa {
    font-family: "Impulsa", sans-serif;
    letter-spacing: 1px;
    line-height: 1;
    font-weight: 500;
}

#dia-mundial-diabetes .border-20 {
    border-radius: 20px;
}

#dia-mundial-diabetes .bloque-azul {
    background-color: var(--azul);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    column-gap: 40px;
    row-gap: 20px;
    padding: 60px 20px;
    border-radius: 20px;
}

#dia-mundial-diabetes .bloque-azul img {
    max-width: 100px;
}

#dia-mundial-diabetes .bloque-azul figcaption {
    text-align: center;
    margin-top: 0.8rem;
}

#dia-mundial-diabetes .bloque-naranja {
    background-color: var(--naranja);
    border-radius: 20px;
    padding: 60px 30px;
}

#dia-mundial-diabetes .text-naranja {
    color: var(--naranja);
}

#dia-mundial-diabetes .titular {
    background: rgb(251,156,87); /* Old browsers */
    background: -moz-linear-gradient(left,  rgba(251,156,87,1) 15%, rgba(0,164,226,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(251,156,87,1) 15%,rgba(0,164,226,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(251,156,87,1) 15%,rgba(0,164,226,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb9c57', endColorstr='#00a4e2',GradientType=1 ); /* IE6-9 */
}

#dia-mundial-diabetes .img-fluid {
    width: 100%;
}

#dia-mundial-diabetes .z-10 {
    z-index: 10;
}

#dia-mundial-diabetes aside h2.impulsa {
    font-size: clamp(2rem,100vw,3rem);
}

#dia-mundial-diabetes aside.bg-naranja-soft {
    background: var(--naranja-soft) !important;
}

#dia-mundial-diabetes #header-main:after {
    content: none;
}

#dia-mundial-diabetes #sociedades {
    display: none;
}

#dia-mundial-diabetes #objetivos .bg-azul.border-20 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

@media (min-width: 789px) {
    #dia-mundial-diabetes .titular {
        color: white;
        max-width: 60vw;
        padding-right: 30px;
        height: 260px;
        min-height: fit-content;
        display: flex;
        justify-content: end;
        align-items: center;
        border-top-right-radius: 20% 80%;
        border-bottom-right-radius: 20% 80%;
        font-size: clamp(2rem, 100vw, 3.5rem);
    }

    #dia-mundial-diabetes .titular span {
        max-width: 65%;
    }

    #dia-mundial-diabetes .hr {
        border-left: 2px solid white;
    }

    #dia-mundial-diabetes ul span {
        font-size: 2rem;
    }

    #dia-mundial-diabetes .bg-naranja-oscuro img {
        max-width: 400px;
    }

    #dia-mundial-diabetes h3.impulsa {
        font-size: clamp(2rem, 100vw, 2.8rem);
    }

    #dia-mundial-diabetes p {
        font-size: 1.2rem;
    }

    #dia-mundial-diabetes #educar .titular {
        padding-right: 70px;
    }

    #dia-mundial-diabetes #pinchado {
        max-width: 300px;
        margin-left: auto;
    }
}

@media (max-width: 1280px) and (min-width: 789px) {
    #dia-mundial-diabetes .container {
        max-width: 1080px;
        padding: 0 30px;
    }

    #dia-mundial-diabetes .titular {
        height: 220px;
    }

    #dia-mundial-diabetes .titular span {
        max-width: 80%;
    }

    #dia-mundial-diabetes #educar .col-md-8 {
        flex: 0 0 85%;
        max-width: 85%;
    }

    #dia-mundial-diabetes svg {
        max-width: 200px;
        max-height: 200px;
    }
}

@media (max-width: 788px) {
    #dia-mundial-diabetes .titular {
        color: white;
        max-width: 95vw;
        padding-right: 10px;
        height: 200px;
        min-height: fit-content;
        display: flex;
        justify-content: end;
        align-items: center;
        border-top-right-radius: 40% 90%;
        border-bottom-right-radius: 40% 90%;
        font-size: clamp(1.6rem, 100vw, 2rem);
        padding-left: 20px;
    }

    #dia-mundial-diabetes #pinchado {
        position: absolute;
        margin-left: auto;
        right: 0;
        left: 0;
        bottom: 0;
        max-width: 160px;
        margin-bottom: -2px;
    }

    #dia-mundial-diabetes .position-mobile-static {
        position: static;
    }

    #dia-mundial-diabetes .hr {
        border-top: 2px solid white;
    }

    #dia-mundial-diabetes #objetivos li {
        text-align: center;
        padding: 0 20px;
    }

    #dia-mundial-diabetes #objetivos span {
        font-size: 1.8rem;
        display: block;
        text-align: center;
    }

    #dia-mundial-diabetes .impulsa {
        line-height: 1;
    }

    #dia-mundial-diabetes .bloque-naranja .impulsa {
        font-size: clamp(1.3rem, 100vw, 2rem);
    }

    #dia-mundial-diabetes aside .impulsa {
        line-height: 0.8;
    }

    #dia-mundial-diabetes #objetivos h3.impulsa {
        font-size: clamp(1.5rem, 100vw, 2.1rem);
    }

    #dia-mundial-diabetes aside.bg-naranja-soft .impulsa {
        line-height: 1;
    }

    #dia-mundial-diabetes .bg-naranja-soft {
        background: var(--naranja-soft);
    }

    #dia-mundial-diabetes .container {
        padding: 0 30px;
    }

    #dia-mundial-diabetes svg {
        max-width: 100%;
    }
}